<template>
    <div class="container mb-3">
        <!-- {{ dataContent }} -->
        <div class="row">
            <div class="col-md-6">
                <h3>Mail Template</h3>
            </div>
            <div class="col-md-6">
                <!-- <pre>{{ form }} {{ query }}</pre> -->
                <button class="btn btn-primary " v-if="!seen" @click="seen = !seen" style="float: right;" id="btnAdd">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Template</button>
                <button class="btn btn-primary" v-if="seen" @click="seen = !seen" style="float: right;" id="btnHide">
                    <i class="fa fa-minus-circle" aria-hidden="true"></i>
                    Hide</button>
            </div>
        </div>
        <div class="row" v-if="seen">
            <div class="col">

                <form @submit="onSubmit" class="country-from vendor-mas">
                    <div class="row">

                        <div class="col-md-4 mb-3">
                        <label for="mailSubject" class="form-label">Mail Subject </label>
                        <input type="text" v-model="mailSubject" class="form-control" id="mailSubject"
                            placeholder="Mail Subject" required>
                        <div v-if="errors.mailSubject" class="invalid-feedback">{{ errors.mailSubject }}</div>
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-3">

                        <label for="name" class="form-label lbl-bg">Mail Content, For Dynamic fields : { { companyName }
                                },{ { contactPerson } },{ { address1 } },{ { address2 } },{ { cityName } },{ {emailId }
                                },{ { mobileNo} },{ {groupTags } } without Space</label>
                        </div>

                    <div class="col-md-12 mb-3">
                        <ckeditor id="name" :editor="editor" v-model="dataContent" :config="editorConfig"></ckeditor>
                        <div v-if="errors.dataContent" class="invalid-feedback">{{ errors.dataContent }}</div>
                    </div>
                    <div class="btn-bottom">
                        <input type="submit" :value="btnText" class="btn  btn-primary" />
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
    <div class="container">
        <hr />
        <div class="table-responsive">
            <table class="table" ref="mailTemplateTable">
                <thead>
                    <tr>
                        <th>#ID</th>
                        <th>Mail Subject</th>
                        <th>dataContent</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="template in templates" :key="template.id">
                        <td>{{ template.id }}</td>
                        <td>{{ template.mailSubject }}</td>
                        <td>{{ template.dataContent }}</td>
                        <td><button class="btn btn-success btn-sm" @click="editdata(template.id)"> <i
                                    class="fa fa-pencil" aria-hidden="true"></i> Edit</button> <button
                                class="btn btn-danger btn-sm" @click="deletedata(template.id)"><i class="fa fa-trash"
                                    aria-hidden="true"></i> Delete</button> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="container" v-if="loading">
        <div class="row">
            <div class="col">
                <Spinner />
            </div>
        </div>
    </div>
</template>
<script>
import { MailTemplate } from '../services/MailTemplate';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Spinner from '../components/Spinner.vue';
import $ from "jquery";
import dt from "datatables.net";

export default {
    name: 'MailTemplate',
    components: {
        Spinner
    },
    data() {
        return {
            loading: false,
            templates: [],
            id: '',
            mailSubject: '',
            value: null,
            options: [

            ],
            btnText: 'Save',
            errorMasage: null,
            errors: [],
            seen: false,
            editor: ClassicEditor,
            dataContent: '<p>Content of the editor.</p>',
            dt: dt,
            editorConfig: {
                // The configuration of the editor.
            }
        }
    },
    mounted() {
        this.dt = $(this.$refs.mailTemplateTable).DataTable();
    },
    watch: {
        templates() {
            this.dt.destroy();
            this.$nextTick(() => {
                this.dt = $(this.$refs.mailTemplateTable).DataTable()
            })
        }
    },
    async created() {
        try {
            this.loading = true;
            let response = await MailTemplate.getMailTemplates();
            this.templates = response.data;

            // console.log(this.templates);
            this.loading = false;
        }
        catch (error) {
            this.errorMasage = error;
            this.loading = false;
        }
    }, methods: {
        async onSubmit(e) {
            e.preventDefault()
            if (!this.mailSubject) {
                alert('Please Enter Subject')
                return
            }

            // ClassicEditor.instances.IDofEditor.insertText('some text here');
            // ClassicEditor.instances['idofeditor'].insertText(this.mailSubject);

            if (!this.dataContent) {
                alert('Please Enter Some Content')
                return
            }
            const form = {
                "mailSubject": this.mailSubject,
                "dataContent": this.dataContent,
            }
            // const response = await MailTemplate.createCountry(form);
            if (this.btnText === 'Save') {
                try {
                    this.loading = true;
                    const response = await MailTemplate.createMailTemplate(form);
                    // console.log(response);
                    this.$swal(
                        'Mail Template Created ',
                        response.data.status,
                        'success'
                    );
                    this.dataContent = "";
                    this.mailSubject = "";
                    this.loading = false;
                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        console.log(error.response.data.errors.dataContent);

                    }
                    this.loading = false;
                    // console.log(errors.value.dataContent);
                }
            } else {
                // alert('update');
                try {
                    this.loading = true
                    const response = await MailTemplate.updateMailTemplate(form, this.id);
                    // console.log(response);
                    this.$swal(
                        'Mail Template Updated ',
                        response.data.status,
                        'success'
                    );
                    this.dataContent = "";
                    this.mailSubject = "";
                    this.loading = false;
                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        console.log(error.response.data.errors.dataContent);

                    }
                    this.loading = false;
                    // console.log(errors.value.dataContent);
                }
                this.btnText = "Save";
            }
            this.loading = true
            let response = await MailTemplate.getMailTemplates()
            this.templates = response.data;
            this.loading = false


        },
        async editdata(id) {
            this.loading = true;
            // alert(id);
            this.btnText = "Update";
            const response = await MailTemplate.getMailTemplate(id)
            this.id = id;
            this.mailSubject = response.data.mailSubject;
            this.dataContent = response.data.dataContent;
            // console.log(response.data.dataContent);
            // this.btnText = "Save";
            this.loading = false;
            this.seen = true;
        },
        async deletedata(id) {
            let result = await this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });
            // alert(result.isConfirmed);
            if (result.isConfirmed) {
                this.loading = true;
                const resDel = await MailTemplate.deleteMailTemplate(id);
                // console.log(resDel);
                if (resDel.status == 204) {
                    this.$swal(
                        'Mail Template Deleted',
                        resDel.data.status,
                        'success'
                    );
                }

            }

            // alert(id);

            // this.loading = true;
            let response = await MailTemplate.getMailTemplates();
            this.templates = response.data;
            this.loading = false;
        }
    },
}
</script>
<style scoped>
.vendor-mas {
    background: #fbfbfb;
    border: solid 1px #d2d2d2;
    padding: 30px;
    margin: 20px 0px;
}
.vendor-mas .form-control {
    padding: 12px 10px;
    border-radius: 0;
 
}
.multiselect {
    border-radius: 0;
    padding: 4px 10px;
}
.btn-bottom {
    text-align: center;
}
.vendor-mas .btn.btn-primary {
    border-radius: 3px;
    padding: 10px 25px;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
}
.lbl-bg {
    background:#e5f5ff;
    border: solid 1px #d2d2d2;
    padding: 10px;
}
.table > thead {
    vertical-align: bottom;
    background: lightskyblue;
}
tbody tr td {
    border-right: 1px solid #a7daf9;
}
tbody tr td:first-child {
    border-left: 1px solid #a7daf9;
}


tr.odd  { background-color:#FFF; vertical-align: middle; }
tr.even { background-color:#e5f5ff; vertical-align: middle; }

</style>